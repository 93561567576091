/*  eslint-disable */
import Immutable from "seamless-immutable"

export const actionTypes = {
  FORM_INIT: "FORM_INIT",
  FORM_FIELD_VALUE: "FORM_FIELD_VALUE",
  FORM_FIELD_ERROR: "FORM_FIELD_ERROR",
  FORM_SUBMIT: "FORM_SUBMIT",
  FORM_SUBMIT_SUCCESS: "FORM_SUBMIT_SUCCESS",
  FORM_SUBMIT_ERROR: "FORM_SUBMIT_ERROR",
  FORM_SUBMIT_RESET: "FORM_SUBMIT_RESET",
}

const values = (state = Immutable({}), action) => {
  switch (action.type) {
    case actionTypes.FORM_INIT:
      return Immutable(action.initialValues)

    case actionTypes.FORM_FIELD_VALUE:
      return state.setIn([action.fieldName], action.value)

    default:
      return state
  }
}

const errors = (state = Immutable({}), action) => {
  switch (action.type) {
    case actionTypes.FORM_SUBMIT:
      return state.without("_")
    case actionTypes.FORM_FIELD_ERROR:
      if (action.error) {
        return state.setIn([action.fieldName], action.error)
      }
      return state.without(action.fieldName)

    default:
      return state
  }
}

const meta = (state = Immutable({}), action) => {
  switch (action.type) {
    case actionTypes.FORM_SUBMIT:
      return state.setIn(["submitting"], true).setIn(["dirty"], false)

    case actionTypes.FORM_SUBMIT_SUCCESS:
      return state.setIn(["submitting"], false).setIn(["submitted"], true)

    case actionTypes.FORM_SUBMIT_ERROR:
      return state.setIn(["submitting"], false)

    case actionTypes.FORM_SUBMIT_RESET:
      return Immutable({})

    case actionTypes.FORM_FIELD_VALUE:
      return state.setIn(["dirty"], true)

    default:
      return state
  }
}

const combineReducers = reducer => {
  return (state = {}, action) => {
    const keys = Object.keys(reducer)
    const nextReducers = {}
    for (let i = 0; i < keys.length; i += 1) {
      const invoke = reducer[keys[i]](state[keys[i]], action)

      nextReducers[keys[i]] = invoke
    }

    return nextReducers
  }
}

const reducer = (state = Immutable({}), action) => {
  return action && action.formName
    ? state.setIn(
        [action.formName],
        combineReducers({
          values,
          meta,
          errors,
        })(state[action.formName], action)
      )
    : state
}

export default reducer
