import { ThemeProvider } from "@emotion/react"
import { useEffect, useState } from "react"
import Helmet from "react-helmet"
import navigateTo from "@src/utils/navigateTo"
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks"

import defaultConfig from "tailwindcss/defaultConfig"

/*  eslint-disable */
import { GET_CURRENT_USER, LOG_OUT, ME } from "@src/hooks/useUser"
import LoadingSpinner from "@src/components/ui/LoadingSpinner"
import {
  GET_SELECTED_ORGANIZATION,
  MY_ORGANIZATIONS,
} from "@src/hooks/useOrganization"
import DownLoadProgressModal, {
  DownLoadProgressModalData,
} from "@src/components/ui/DownLoadProgressModal"

import FontFace from "./FontFace"
import "./global.css"
import AppContext from "./AppContext"
import { reorderCSSInDevelopment } from "@src/utils/reorderCSSInDevelopment"
import { API_EMPLOYEE_TOKEN, AutoPolicyModalClosedAt } from "@src/utils/constant"

const resolveConfig: any = require("tailwindcss/lib/util/resolveConfig").default
const config = require("../../../tailwind.config.js")
const getConfigFunction = (config: any) => {
  return resolveConfig([config, defaultConfig])
}
const tailwindTheme = getConfigFunction(config)

const media = (key: string) => `
  @media (min-width: ${tailwindTheme.theme.screens[key]})
`

const theme = {
  ...tailwindTheme.theme,
  ...tailwindTheme,
  media,
}

const downloadingReportParam = "downloadingReport"

const Head = () => (
  <Helmet>
    <title>AirMason Admin</title>
    <link rel="preconnect" href="https://app.airmason.com" />
    <link rel="preconnect" href="https://trycom.s3.amazonaws.com" />

    <script async type="text/javascript">
      {`(function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://cdn.firstpromoter.com/fprom.js',t.onload=t.onreadystatechange=function(){var t=this.readyState;if(!t||"complete"==t||"loaded"==t)try{$FPROM.init("lkl3ype8",".airmason.com")}catch(t){}};var e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)})();`}
    </script>
  </Helmet>
)

const useLocationPath = () => {
  const [locationPath, setLocationPath] = useState(typeof window !== "undefined" ? window.location.pathname : "/");

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    const handleLocationChange = () => {
      setLocationPath(window.location.pathname);
    };

    // Override history methods to dispatch custom events
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(this, args);
      window.dispatchEvent(new Event("pushstate"));
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      window.dispatchEvent(new Event("replacestate"));
    };

    // Listen to events
    window.addEventListener("popstate", handleLocationChange);
    window.addEventListener("pushstate", handleLocationChange);
    window.addEventListener("replacestate", handleLocationChange);

    return () => {
      // Clean up
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
      window.removeEventListener("popstate", handleLocationChange);
      window.removeEventListener("pushstate", handleLocationChange);
      window.removeEventListener("replacestate", handleLocationChange);
    };
  }, []);

  return locationPath;
};

const REQUIRED_LOGIN = {
  dashboard: RegExp(/^\/$/),
  team: RegExp(/^\/team\/?.*$/),
  handbook: RegExp(/^\/handbook\/?.*$/),
  folder: RegExp(/^\/folder\/?.*$/),
  profile: RegExp(/^\/profile\/?.*$/),
  integrations: RegExp(/^\/integrations\/?.*$/),
  internal: RegExp(/^\/internal\/?.*$/),
  create: RegExp(/^\/create\/?.*$/),
  switchOrganization: RegExp(/^\/switch-organization\/?.*$/),
  hris: RegExp(/^\/hris\/?.*$/),
}

function requiredLogin(): boolean {
  const pathName = useLocationPath()

  return (
    REQUIRED_LOGIN.dashboard.test(pathName) ||
    REQUIRED_LOGIN.team.test(pathName) ||
    REQUIRED_LOGIN.handbook.test(pathName) ||
    REQUIRED_LOGIN.folder.test(pathName) ||
    REQUIRED_LOGIN.profile.test(pathName) ||
    REQUIRED_LOGIN.integrations.test(pathName) ||
    REQUIRED_LOGIN.internal.test(pathName) ||
    REQUIRED_LOGIN.create.test(pathName) ||
    REQUIRED_LOGIN.switchOrganization.test(pathName) ||
    REQUIRED_LOGIN.hris.test(pathName)
  )
}

export const checkForRedirect = (reload: boolean, opts: any | undefined = {}, isInLoginPage = false) => {
  const params = new URLSearchParams(window.location.search)
  const portal = params.get("portal")
  const path = params.get("path")

  switch (portal) {
    case "editor":
      if (!path) {
        break
      }
      navigateTo.editHandbook(path)
      break;

    case "employee":
      navigateTo.employeeHandbook(path)
      break;

    case "admin":
      navigateTo.dashboard(opts, reload)
      break;

    default:
      if (isInLoginPage) {
        navigateTo.dashboard(opts, reload)
      }
      break;
  }
}

export default function Layout({ children }: { children: any }): any {
  const pathName = useLocationPath()
  const isInLoginPage = RegExp(/^\/login\/?.*$/).test(pathName)
  const isRequiredLogin = requiredLogin()
  const [downLoadProgressModalData, setDownLoadProgressModalData] =
    useState<DownLoadProgressModalData | null>()
  // client
  const client = useApolloClient()

  // fetch user for all the pages
  const userResp = useQuery(ME, {
    fetchPolicy: "no-cache",
  })

  // logout mutation
  const [logout] = useMutation(LOG_OUT)

  // user
  const user = userResp && userResp.data && userResp.data.me

  // fetch organizations only if user data is available
  const orgsResp = useQuery(MY_ORGANIZATIONS, {
    // skip: !user,
  })

  // organization
  const organizations =
    (orgsResp && orgsResp.data && orgsResp.data.getMyOrganizations) || []

  async function handleFullLogout(): Promise<void> {
    try {
      await logout()
      localStorage.removeItem(AutoPolicyModalClosedAt)
      localStorage.removeItem(API_EMPLOYEE_TOKEN)

      await client.resetStore()
    } catch (error) {
      console.log("Logout Error: ", error)
    }
  }

  useEffect(() => {
    reorderCSSInDevelopment()
  }, [])

  // set selected organization
  useEffect(() => {
    // user or organizations don't exist
    if (!user || organizations.length === 0) {
      return
    }

    // select default organization
    let organization = organizations.find(
      (item: any) => item.id === user.selectedOrganization.id
    )

    if (!organization) {
      organization = organizations[0]
    }

    const userAccountType = user.organizationUserRelations[0].userAccountType
    const selectedOrganization = user.selectedOrganization

    // write data to apollo store
    client.writeQuery({
      query: GET_SELECTED_ORGANIZATION,
      data: {
        selectedOrganization: {
          ...selectedOrganization,
          id: parseFloat(selectedOrganization.id),
          userAccountType,
        },
      },
    })
  }, [user, organizations])

  useEffect(() => {
    // if you are on the login page and you already have user data + organizations
    // redirect to the dashboard
    if (isInLoginPage && !userResp.loading && !orgsResp.loading && user && organizations.length > 0) {
      checkForRedirect(false, undefined, isInLoginPage)
    }
  }, [userResp.loading, orgsResp.loading, user, organizations])

  useEffect(() => {
    if (!user) {
      return
    }

    const userAccountType = user.organizationUserRelations[0].userAccountType

    // write data to apollo store
    client.writeQuery({
      query: GET_CURRENT_USER,
      data: {
        currentUser: {
          ...user,
          id: parseFloat(user.id),
          userAccountType,
        },
      },
    })
  }, [user])

  useEffect(() => {
    if (!orgsResp.called || orgsResp.loading) {
      return;
    }
    if (!isInLoginPage && user && organizations.length === 0) {
      // logout the user
      const goToLogin = () => navigateTo.login({
        state: { loginError: "You are not authorized to access this page. Code 2" }
      })
      handleFullLogout()
        .then(goToLogin)
        .catch(goToLogin)
    }
  }, [isInLoginPage, user, userResp.error, orgsResp.loading, orgsResp.called, orgsResp.error, organizations.length])

  // show loader while we fetch user or organizations
  if (
    !userResp.called ||
    userResp.loading ||
    !orgsResp.called ||
    orgsResp.loading
  ) {
    return <LoadingSpinner />
  }

  // when login is required but there is an error fetching user info
  // navigate to login page and still render the layout at the end of this function
  if (isRequiredLogin && (userResp.error || !user)) {
    // save the current url to local storage to redirect back after login
    const currentURL = window.location.pathname + window.location.search
    const isDownloadingReportParam = currentURL.includes(downloadingReportParam)
    if (isDownloadingReportParam) {
      navigateTo.login({
        state: { redirectUrl: currentURL },
      })
    } else {
      navigateTo.login()
    }
  }

  if (isInLoginPage && user && organizations.length > 0 && !userResp.error) {
    checkForRedirect(false, undefined, isInLoginPage)
    return <LoadingSpinner />
  }

  return (
    <ThemeProvider theme={theme}>
      <FontFace />
      <Head />
      <AppContext.Provider
        value={{
          downLoadProgressModal: {
            showDownLoadProgressModal: setDownLoadProgressModalData,
            isShowing: !!downLoadProgressModalData,
          },
        }}
      >
        {children}
      </AppContext.Provider>

      {!!downLoadProgressModalData && (
        <DownLoadProgressModal
          isOpen={!!downLoadProgressModalData}
          onClose={() => setDownLoadProgressModalData(null)}
          data={downLoadProgressModalData}
        />
      )}
    </ThemeProvider>
  )
}
