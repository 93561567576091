import React, { useEffect } from "react"
import styled, { StyledComponent } from "@emotion/styled"
import tw from "twin.macro"
import { logSpinnerTimeout } from "@src/utils/logVault"

const Styled: StyledComponent<any, any, any> & any = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full flex items-center justify-center`}
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;

  .loader {
    ${tw`block w-12 h-12 border border-4 border-gray-100 rounded-full mx-auto`}
    border-top: 4px solid #555;
    animation: spin 1s linear infinite;
  }
`

const TextLoadingStyled = styled.div`
  ${tw`text-lg font-avenirRoman mt-3 text-center text-white`}
  max-width: 445px;
`

const LoadingSpinner: React.FC<any> = ({ children }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      logSpinnerTimeout('Spinner exceeded 30 seconds');
    }, 30000);

    return () => clearTimeout(timeout); // Clear timeout on unmount
  }, []);

  return (
    <Styled>
      <div>
        <div className="loader" />
        {children && <TextLoadingStyled>{children}</TextLoadingStyled>}
      </div>
    </Styled>
  )
}

export default LoadingSpinner
