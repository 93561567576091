import styled, { StyledComponent } from "@emotion/styled"
import tw from "twin.macro"

const ModalStyle: StyledComponent<any, any, any> & any = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  width: 550px;
  margin: 20px auto;
  visibility: hidden;
  opacity: 0;

  &.fade-in {
    transition: opacity linear 0.15s;
    visibility: visible;
    opacity: 1;
  }

  &.fade-out {
    transition: visibility 0s linear 300ms, opacity 300ms;
    visibility: hidden;
    opacity: 0;
  }

  .background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }

  &.no-scroll {
    .box-dialog {
      .box-content-wrapper {
        overflow: visible;
        max-height: max-content;
      }
    }
  }

  .box-dialog {
    z-index: 1050;
    width: 100%;
    padding: 40px 20px 40px 40px;
    background-color: #fefefe;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
    position: relative;
    max-height: 100%;

    .box-content-wrapper {
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      max-height: 470px;
      padding-right: 20px;

      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-thumb {
        background: #3e3e41;
        border-radius: 2.5px;
      }
    }

    .close {
      min-width: 17px;
      margin-top: 6px;
    }
  }

  &.tab-modal {
    max-height: 650px;
    margin: auto;
    align-items: start;
    .box-dialog {
      padding-top: 70px;
      .close {
        position: absolute;
        right: 40px;
        top: 30px;
        margin-top: 0;
      }
      .box-content-wrapper {
        position: initial;
        max-height: 100%;
        .navbar-tab {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          height: 70px;
          padding-bottom: 1px;
        }
        .tab-content {
          /* margin-top: 20px; */
          max-height: 566px;
        }
      }
    }
    &.tab-modal.no-scroll {
      .tab-content {
      }
    }
  }
  .overflow-y-auto {
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: #3e3e41;
      border-radius: 2.5px;
    }
  }

  &.custom-bottom-modal {
    .box-dialog {
      padding-bottom: 100px;
      .box-content-wrapper {
        position: initial;
        ${tw`pr-0`}
        max-height: 550px;
        .box-content {
          .bottom-wrapper {
            ${tw`absolute bottom-0 left-0 w-full bg-gray-22`}
            height: 100px;
            padding: 30px 40px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }
      }
    }
  }

  &.v2 {
    .modal-title {
      font-size: 20px;
      margin-bottom: 15px;
    }

    .box-dialog {
      padding: 30px 40px;
    }

    .box-content-wrapper {
      padding: 0;
    }

    .close {
      top: 40px;
      right: 40px;
    }
  }
`

export default ModalStyle
