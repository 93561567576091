const apiUrl = process.env.GATSBY_API_SERVER_URL || "https://api.airmason.com"
const handbookViewerUrl =
  process.env.GATSBY_HANDBOOK_VIEWER_DOMAIN || "https://books.airmason.dev"

export default {
  // Server & Domain Urls
  API_SERVER_URL: apiUrl,
  API_AI_GENERATOR_URL: process.env.GATSBY_API_AI_GENERATOR_URL || "",
  GRAPHQL_SERVER_URL: `${apiUrl}/graphql`,
  EDITOR_DOMAIN:
    process.env.GATSBY_EDITOR_DOMAIN || "https://editor.airmason.com",
  HANDBOOK_VIEWER_DOMAIN: handbookViewerUrl,
  WEB_LANDING_DOMAIN:
    process.env.GATSBY_WEB_LANDING_DOMAIN || "https://www.airmason.dev",
  LOG_VAULT_URL: process.env.GATSBY_LOG_VAULT_URL || "",

  // Date time formats
  DATE_FORMAT: "MM/DD/YYYY",
  TIME_FORMAT: "HH:mm",
  TIME_SECOND_FORMAT: "HH:mm:ss",
  DATE_TIME_FORMAT: "MM/DD/YYYY HH:mm",
  DATE_FORMAT_ACTIVITY: "MMM Do, YYYY",
  DATE_TIME_FORMAT_ACTIVITY: "MMM Do, YYYY [at] HH:mm",
  DATE_HANDBOOK_EMPLOYEE_ACCESS: "MMM-DD-YYYY",
  REMINDER_TIME_FORMAT: "MMM DD, YYYY",
  TIME_PRE_REGISTRATION_SIGN_ON: "MMM-DD-YYYY @ HH:mma",
  RENEW_DATE_RANGE_FORMAT: "YYYY-MM-DD",
  LAST_SEEN_LOOKUP: "MM/DD/YYYY @ HH:mm",
  TIME_EMAIL_LOOKUP_LIST: "MMM Do, YYYY HH:mm",
  TIME_ACTIVITY_LOOKUP_LIST: "MMM Do, YYYY @ HH:mm",
  TIME_ANALYTICS_LIST: "MMM Do, YYYY — HH:mm",

  RIPPLING_APP_URL: process.env.GATSBY_RIPPLING_APP_URL || "",
  AUTOMATED_POLICY_UPDATES_MARKETING_PAGE:
    "https://airmason.com/automated-policy-updates",
  CALENDLY: {
    ENTERPRISE: "https://calendly.com/airmason-sales/airmason-enterprise-demo",
    AUTOMATED_POLICY_UPDATES:
      "https://calendly.com/airmason-sales/automated-policy-updates",
    BOOK_A_CALL:
      "https://calendly.com/justin-airmason/airmason-enterprise-demo?utm_source=homepage&utm_medium=register&utm_campaign=homepage&month=2020-05",
  },
  HELP_CENTER: "https://support.airmason.com",
}

/*
dev config
const apiUrl =
  process.env.GATSBY_API_SERVER_URL || "https://api.airmason.dev"
const handbookViewerUrl =
  process.env.GATSBY_HANDBOOK_VIEWER_DOMAIN || "https://books.airmason.dev"
*/
