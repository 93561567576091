import styled from "@emotion/styled"

import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

const CircularProgressbarStyled = styled.div`
  .CircularProgressbar {
    width: 30px;
  }
`

export interface CircularProgressbarLayoutProps {
  percent: number
}

function CircularProgressbarLayout(props: CircularProgressbarLayoutProps) {
  const { percent } = props
  return (
    <CircularProgressbarStyled className="flex items-center">
      <div className="mr-3 text-black-1">{percent}%</div>
      <CircularProgressbar value={percent} strokeWidth={15} />
    </CircularProgressbarStyled>
  )
}

export default CircularProgressbarLayout
