exports.components = {
  "component---src-pages-403-tsx": () => import("./../../../src/pages/403.tsx" /* webpackChunkName: "component---src-pages-403-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-collaborator-complete-signup-tsx": () => import("./../../../src/pages/collaborator-complete-signup.tsx" /* webpackChunkName: "component---src-pages-collaborator-complete-signup-tsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-employee-signup-tsx": () => import("./../../../src/pages/employee-signup.tsx" /* webpackChunkName: "component---src-pages-employee-signup-tsx" */),
  "component---src-pages-folder-tsx": () => import("./../../../src/pages/folder.tsx" /* webpackChunkName: "component---src-pages-folder-tsx" */),
  "component---src-pages-get-one-time-token-tsx": () => import("./../../../src/pages/get-one-time-token.tsx" /* webpackChunkName: "component---src-pages-get-one-time-token-tsx" */),
  "component---src-pages-handbook-tsx": () => import("./../../../src/pages/handbook.tsx" /* webpackChunkName: "component---src-pages-handbook-tsx" */),
  "component---src-pages-hris-tsx": () => import("./../../../src/pages/hris.tsx" /* webpackChunkName: "component---src-pages-hris-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrations-index-tsx": () => import("./../../../src/pages/integrations/index.tsx" /* webpackChunkName: "component---src-pages-integrations-index-tsx" */),
  "component---src-pages-internal-auto-policy-manager-edit-tsx": () => import("./../../../src/pages/internal/auto-policy-manager/edit.tsx" /* webpackChunkName: "component---src-pages-internal-auto-policy-manager-edit-tsx" */),
  "component---src-pages-internal-auto-policy-manager-index-tsx": () => import("./../../../src/pages/internal/auto-policy-manager/index.tsx" /* webpackChunkName: "component---src-pages-internal-auto-policy-manager-index-tsx" */),
  "component---src-pages-internal-compose-email-tsx": () => import("./../../../src/pages/internal/compose-email.tsx" /* webpackChunkName: "component---src-pages-internal-compose-email-tsx" */),
  "component---src-pages-internal-handbook-generator-tsx": () => import("./../../../src/pages/internal/handbook-generator.tsx" /* webpackChunkName: "component---src-pages-internal-handbook-generator-tsx" */),
  "component---src-pages-internal-handbook-regions-tsx": () => import("./../../../src/pages/internal/handbook-regions.tsx" /* webpackChunkName: "component---src-pages-internal-handbook-regions-tsx" */),
  "component---src-pages-internal-lookups-new-activities-tsx": () => import("./../../../src/pages/internal/lookups-new/activities.tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-activities-tsx" */),
  "component---src-pages-internal-lookups-new-collaborators-tsx": () => import("./../../../src/pages/internal/lookups-new/collaborators.tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-collaborators-tsx" */),
  "component---src-pages-internal-lookups-new-emails-tsx": () => import("./../../../src/pages/internal/lookups-new/emails.tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-emails-tsx" */),
  "component---src-pages-internal-lookups-new-employees-tsx": () => import("./../../../src/pages/internal/lookups-new/employees.tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-employees-tsx" */),
  "component---src-pages-internal-lookups-new-forms-copywriting-[id]-tsx": () => import("./../../../src/pages/internal/lookups-new/forms/copywriting/[id].tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-forms-copywriting-[id]-tsx" */),
  "component---src-pages-internal-lookups-new-forms-design-[id]-tsx": () => import("./../../../src/pages/internal/lookups-new/forms/design/[id].tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-forms-design-[id]-tsx" */),
  "component---src-pages-internal-lookups-new-forms-index-tsx": () => import("./../../../src/pages/internal/lookups-new/forms/index.tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-forms-index-tsx" */),
  "component---src-pages-internal-lookups-new-forms-integration-[id]-tsx": () => import("./../../../src/pages/internal/lookups-new/forms/integration/[id].tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-forms-integration-[id]-tsx" */),
  "component---src-pages-internal-lookups-new-forms-new-client-[id]-tsx": () => import("./../../../src/pages/internal/lookups-new/forms/new-client/[id].tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-forms-new-client-[id]-tsx" */),
  "component---src-pages-internal-lookups-new-handbooks-tsx": () => import("./../../../src/pages/internal/lookups-new/handbooks.tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-handbooks-tsx" */),
  "component---src-pages-internal-lookups-new-index-tsx": () => import("./../../../src/pages/internal/lookups-new/index.tsx" /* webpackChunkName: "component---src-pages-internal-lookups-new-index-tsx" */),
  "component---src-pages-internal-lookups-tsx": () => import("./../../../src/pages/internal/lookups.tsx" /* webpackChunkName: "component---src-pages-internal-lookups-tsx" */),
  "component---src-pages-internal-master-policies-tsx": () => import("./../../../src/pages/internal/master-policies.tsx" /* webpackChunkName: "component---src-pages-internal-master-policies-tsx" */),
  "component---src-pages-internal-new-account-invite-owner-tsx": () => import("./../../../src/pages/internal/new-account-invite-owner.tsx" /* webpackChunkName: "component---src-pages-internal-new-account-invite-owner-tsx" */),
  "component---src-pages-internal-policies-update-tsx": () => import("./../../../src/pages/internal/policies-update.tsx" /* webpackChunkName: "component---src-pages-internal-policies-update-tsx" */),
  "component---src-pages-internal-send-welcome-email-tsx": () => import("./../../../src/pages/internal/send-welcome-email.tsx" /* webpackChunkName: "component---src-pages-internal-send-welcome-email-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-payment-jsx": () => import("./../../../src/pages/payment.jsx" /* webpackChunkName: "component---src-pages-payment-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-redirect-jsx": () => import("./../../../src/pages/redirect.jsx" /* webpackChunkName: "component---src-pages-redirect-jsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-switch-organization-tsx": () => import("./../../../src/pages/switch-organization.tsx" /* webpackChunkName: "component---src-pages-switch-organization-tsx" */),
  "component---src-pages-team-account-details-tsx": () => import("./../../../src/pages/team/account-details.tsx" /* webpackChunkName: "component---src-pages-team-account-details-tsx" */),
  "component---src-pages-team-auto-policies-tsx": () => import("./../../../src/pages/team/auto-policies.tsx" /* webpackChunkName: "component---src-pages-team-auto-policies-tsx" */),
  "component---src-pages-team-collaborators-tsx": () => import("./../../../src/pages/team/collaborators.tsx" /* webpackChunkName: "component---src-pages-team-collaborators-tsx" */),
  "component---src-pages-team-company-details-tsx": () => import("./../../../src/pages/team/company-details.tsx" /* webpackChunkName: "component---src-pages-team-company-details-tsx" */),
  "component---src-pages-team-domain-settings-tsx": () => import("./../../../src/pages/team/domain-settings.tsx" /* webpackChunkName: "component---src-pages-team-domain-settings-tsx" */),
  "component---src-pages-team-employee-portal-tsx": () => import("./../../../src/pages/team/employee-portal.tsx" /* webpackChunkName: "component---src-pages-team-employee-portal-tsx" */),
  "component---src-pages-team-export-employee-tsx": () => import("./../../../src/pages/team/export-employee.tsx" /* webpackChunkName: "component---src-pages-team-export-employee-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

