import gql from "graphql-tag"

import useApolloEntity from "./useApolloEntity"

export const USER_ACCOUNT_TYPE_FRAGMENT = gql`
  fragment UserAccountTypeFragment on UserAccountType {
    id
    accountType
    isOwner
    isEmployeeAdmin
    isIntegrationAdmin
    isCollaboratorAdmin
    canCreateHandbook
    isOrganizationConfigurationAdmin
    isOrganizationDetailsAdmin
    canCreateFolder
    canViewFolder
    isHandbookEditor
    isHandbookPublisher
    isHandbookAdmin
    canViewSignatures
    isFolderAdmin
  }
`

const GET_USER_ACCOUNT_TYPES = gql`
  query GetUserAccountTypes($organizationId: Float!) {
    getUserAccountTypes(organizationId: $organizationId) {
      ...UserAccountTypeFragment
    }
  }
  ${USER_ACCOUNT_TYPE_FRAGMENT}
`

const useUserAccountType = () => {
  const UserAccountType = useApolloEntity<{
    get: any
  }>({
    useLazyQuery: {
      get: {
        query: GET_USER_ACCOUNT_TYPES,
        extractEntity(data: any): any {
          return data && data.getUserAccountTypes
        },
      },
    },
  })

  return UserAccountType
}

export interface USER_ACCOUNT_TYPES {
  id: string
  accountType: string
  isOwner: boolean
  isBillingAdmin: boolean
  isEmployeeAdmin: boolean
  isIntegrationAdmin: boolean
  isCollaboratorAdmin: boolean
  isHandbookContentManager: boolean
  canEditHandbook: boolean
  canCreateHandbook: boolean
  canDeleteHandbook: boolean
  canPublishHandbook: boolean
  isHandbookContentCreator: boolean
}

export interface SetRights {
  rights: string
  label: string
  checked?: boolean
  tooltip?: string
  disabled?: boolean
}

export const setRightsAccountType: SetRights[] = [
  { rights: "canEditHandbook", label: "Edit Content", checked: false },
  {
    rights: "canPublishHandbook",
    label: "Edit and publish content",
    checked: false,
  },
  {
    rights: "canCreateHandbook",
    label: "Create new handbook(s)",
    checked: false,
  },
  { rights: "canDeleteHandbook", label: "Delete handbooks", checked: false },
  // { rights: "isBillingAdmin", label: "Have access to billing", checked: false },
  {
    rights: "isIntegrationAdmin",
    label: "Access to integrations",
    checked: false,
  },
  { rights: "isEmployeeAdmin", label: "Access to employees", checked: false },
  {
    rights: "isCollaboratorAdmin",
    label: "Access to collaborators",
    checked: false,
  },
]

export const setRightsAccountTypeGuest: SetRights[] = [
  {
    rights: "canViewSignatures",
    label: "View signatures",
    tooltip: "View signatures",
  },
  {
    rights: "canEditSignatureSettings",
    label: "Edit signature settings",
    tooltip: "Edit Signature Settings",
  },
  {
    rights: "canEditReminderSettings",
    label: "Edit reminder settings",
    tooltip: "Edit reminder Settings",
  },
  {
    rights: "canEditCollaboratorAccess",
    label: "Edit collaborator access",
    tooltip: "Edit collaborator access",
  },
  {
    rights: "canViewHandbook",
    label: "View document",
    tooltip: "View document",
  },
  {
    rights: "canEditEmployeeAccess",
    label: "Edit employee access",
    tooltip: "Edit employee access",
  },
]

export const CUSTOM_ACCOUNT_TYPE = {
  id: 0,
  accountType: "Custom",
}

export const ACCOUNT_TYPE = {
  GuestHandbookEditor: "Guest Handbook Editor",
  HandbookContentEditor: "Handbook Content Editor",
  HandbookContentManager: "Handbook Content Manager",
  AccountAdmin: "Account Admin",
  Owner: "Owner",
}

export const UserAccountType = {
  isGuest: (userAccountType: string) => userAccountType === ACCOUNT_TYPE.GuestHandbookEditor,

  isHandbookContentManager: (userAccountType: string) => userAccountType === ACCOUNT_TYPE.HandbookContentManager,
}

export const rightsAccessHandbookForGuest: SetRights[] = [
  {
    rights: "canEditHandbook",
    label: "Edit content",
    tooltip: "Edit content",
  },
  {
    rights: "canPublishHandbook",
    label: "Publish changes",
    tooltip: "Publish changes",
  },
  ...setRightsAccountTypeGuest,
]

export default useUserAccountType
