import React from "react"
import { Global, css } from "@emotion/react"

const FontFace: React.FunctionComponent<{}> = () => (
    <Global
      styles={css`
        @font-face {
          font-family: AvenirBook;
          src: url("/fonts/Avenir-Book.ttf") format("truetype");
        }
        @font-face {
          font-family: AvenirHeavy;
          src: url("/fonts/Avenir-Heavy.otf") format("opentype");
        }
        @font-face {
          font-family: AvenirMedium;
          src: url("/fonts/Avenir-Medium.ttf") format("truetype");
        }
        @font-face {
          font-family: CircularStdMedium;
          src: url("/fonts/CircularStd-Medium.otf") format("opentype");
        }
        @font-face {
          font-family: CircularStdBook;
          src: url("/fonts/CircularStd-Book.otf") format("opentype");
        }
        @font-face {
          font-family: LatoRegular;
          src: url("/fonts/Lato-Regular.ttf") format("truetype");
        }
        @font-face {
          font-family: AvenirRoman;
          src: url("/fonts/Avenir-Roman.ttf") format("truetype");
        }
        @font-face {
          font-family: LatoRegular;
          src: url("/fonts/Lato-Regular.ttf") format("truetype");
        }
        @font-face {
          font-family: LatoItalic;
          src: url("/fonts/Lato-Italic.ttf") format("truetype");
        }
        @font-face {
          font-family: LatoBold;
          src: url("/fonts/Lato-Bold.ttf") format("truetype");
        }
        @font-face {
          font-family: CircularStdMedium;
          src: url("/fonts/CircularStd-Medium.otf") format("opentype");
        }
        @font-face {
          font-family: NunitoSans;
          src: url("/fonts/NunitoSans-Regular.ttf") format("truetype");
        }
        @font-face {
          font-family: NunitoSansBold;
          src: url("/fonts/NunitoSans-Bold.ttf") format("truetype");
        }
        @font-face {
          font-family: AvenirBookOblique;
          src: url("/fonts/Avenir-LightOblique.ttf") format("truetype");
        }
        @font-face {
          font-family: AvenirHeavyOblique;
          src: url("/fonts/Avenir-HeavyOblique.ttf") format("truetype");
        }
        @font-face {
          font-family: AvenirMediumOblique;
          src: url("/fonts/Avenir-MediumOblique.ttf") format("truetype");
        }
      `}
    />
  )

export default FontFace
