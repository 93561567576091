export const reorderCSSInDevelopment = () => {
  if (process.env.NODE_ENV !== "development") return

  setTimeout(() => {
    const head = document.head
    const links = Array.from(head.getElementsByTagName("link"))
    const commonsLink = links.find((link) => link.href.includes("commons.css"))
    if (commonsLink) {
      head.removeChild(commonsLink)
      head.insertBefore(commonsLink, head.firstChild)
    }
  }, 100)
}
