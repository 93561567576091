/*  eslint-disable */

import React from "react"

import Layout from "./src/components/Layout"
import { FormProvider } from "./src/components/Form"
import ApolloProvider from "./src/apolloClient/ApolloProvider"
import Toast from "./src/components/ui/Toast"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"

const cache = createCache({
  key: "emotion",
  prepend: true, // Ensures styles are prepended to the head
})

export const onClientEntry = () => {
  const moveStyleElementToTop = () => {
    const styleElement = document.querySelector('style[data-identity="gatsby-global-css"]');
    if (styleElement) {
      const head = document.head;
      if (head.firstChild !== styleElement) {
        head.insertBefore(styleElement, head.firstChild);
        // disable observer temporarily to avoid infinite loop
        observer.disconnect();

        // re-enable the observer
        observer.observe(document.head, { childList: true, subtree: true });
      }
    }
  };

  const observer = new MutationObserver(() => {
    moveStyleElementToTop();
  });

  observer.observe(document.head, { childList: true, subtree: true });

  // Initial move in case the element is already present
  moveStyleElementToTop();
}

export const wrapRootElement = ({ element }) => {
  return (
    <CacheProvider value={cache}>
    <ApolloProvider>
      <Layout>
        <FormProvider>{element}</FormProvider>
        <Toast />
      </Layout>
    </ApolloProvider>
    </CacheProvider>
  )
}
