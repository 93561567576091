import React from "react"
import { DownLoadProgressModalData } from "@src/components/ui/DownLoadProgressModal"

interface DownLoadProgressModal {
  showDownLoadProgressModal: (data: DownLoadProgressModalData | null) => void
  isShowing: boolean
}

interface AppContextProps {
  downLoadProgressModal: DownLoadProgressModal
}

export default React.createContext({} as AppContextProps)
