const openNewTab = (url: string, name?: string): any => {
  // View more at https://www.w3schools.com/jsref/met_win_open.asp
  name = name || "_blank"

  if (typeof window !== "undefined") {
    return window.open(url, name)
  }
}

export { openNewTab }
