import {
  LazyQueryHookOptions,
  MutationHookOptions,
  useLazyQuery,
  useMutation,
} from "@apollo/react-hooks"
import React from "react"
import stripTypenames from "@src/utils/stripTypenames"
import { ApolloClient, ApolloError, NetworkStatus } from "@apollo/client"

export class ApolloEntity /* implements QueryResult */ {
  client: ApolloClient<any>

  error?: ApolloError

  loading: boolean

  networkStatus: NetworkStatus

  called: boolean

  data: any | undefined

  extractEntity(data: any): any {
    return data
  }

  constructor(queryResult: any) {
    Object.assign(this, queryResult)

    this.client = queryResult.client
    this.error = queryResult.error
    this.loading = queryResult.loading
    this.called = queryResult.called
    this.networkStatus = queryResult.networkStatus
    this.data = this.extractEntity(queryResult.data)
  }
}

export interface ApolloEntityMethods<T = any> {
  [key: string]: T
}

interface UseApolloEntityOptions {
  useLazyQuery?: ApolloEntityMethods<
    {
      query?: any
      extractEntity?(data: any): any
    } & LazyQueryHookOptions
  >
  useMutation?: ApolloEntityMethods<MutationHookOptions & {
    mutation?: any
  }>
}

function useApolloEntity<T extends ApolloEntityMethods = ApolloEntityMethods>(
  options: UseApolloEntityOptions
): T & ApolloEntityMethods {
  const Entity = {} as any

  if (options.useLazyQuery) {
    Object.keys(options.useLazyQuery).forEach(key => {
      const [query, entity] = useLazyQuery(
        options.useLazyQuery![key as any].query!,
        options.useLazyQuery![key as any]
      )

      Entity[key] = (variables: any) => {
        React.useEffect(() => {
          query({ variables })
        }, [])
        const {extractEntity} = options.useLazyQuery![key]
        return new ApolloEntity({
          ...entity,
          data: extractEntity ? extractEntity(entity.data) : entity.data,
        })
      }
    })
  }

  if (options.useMutation) {
    Object.keys(options.useMutation).forEach(key => {
      const [mutation] = useMutation(
        options.useMutation![key].mutation!,
        options.useMutation![key]
      )
      Entity[key as keyof T] = (variables: any) =>
        mutation({ variables: stripTypenames(variables, "__typename") })
    })
  }

  return Entity
}

export default useApolloEntity
