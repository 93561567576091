import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export { toast }

const index = () => (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )

export default index
