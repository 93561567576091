import { ApolloLink, ApolloProvider } from "@apollo/react-hooks"
import { InMemoryCache, ApolloClient, HttpLink, Observable } from "@apollo/client"
import fetch from 'cross-fetch';
import { setContext } from "@apollo/client/link/context";
import React, { ReactElement } from "react"
import { API_EMPLOYEE_TOKEN } from "@src/utils/constant"
import gql from "graphql-tag";
import config from "../config"
import { addActiveRequest, removeActiveRequest, logFailedRequest } from "@src/utils/logVault"

const trackingLink = new ApolloLink((operation, forward) => {
  const requestId = operation.operationName || 'UnknownOperation';
  addActiveRequest(`graphql=>${requestId}`); // Add to active requests

  return new Observable((observer) => {
    forward(operation).subscribe({
      next: (response) => {
        if (response.errors && response.errors.length > 0) {
          // Log GraphQL errors
          logFailedRequest(`graphql=>${requestId}`, {
            errors: response.errors,
            variables: operation.variables,
            query: operation.query?.loc?.source.body,
          });
        }
        removeActiveRequest(`graphql=>${requestId}`); // Remove from active requests
        observer.next(response); // Pass the response to the next link
      },
      error: (error) => {
        removeActiveRequest(`graphql=>${requestId}`); // Remove even on failure
        logFailedRequest(`graphql=>${requestId}`, {
          error: error.message,
          variables: operation.variables,
          query: operation.query?.loc?.source.body,
        });
        observer.error(error); // Propagate the error
      },
      complete: () => observer.complete(), // Complete the observable
    });
  });
});

const httpLink = new HttpLink({
  uri: config.GRAPHQL_SERVER_URL,
  fetch,
  fetchOptions: {
    credentials: "include",
  },
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(API_EMPLOYEE_TOKEN)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "api-token": token || "",
    },
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getEmployeesByFilters: {
          keyArgs: false,
          merge(_existing = [], incoming) {
            return incoming;
          },
        },
      }
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(trackingLink).concat(httpLink),
  cache,
})

// default value in cache
cache.writeQuery({
  query: gql`
    query GetSelectedOrganization {
      selectedOrganization
    }
  `,
  data: {
    selectedOrganization: null,
  },
});

interface IProps {
  children: ReactElement
}

function AirApolloProvider({ children }: IProps) {
  return (
    <ApolloProvider client={client}>
      {React.cloneElement(children)}
    </ApolloProvider>
  )
}

export default AirApolloProvider
