import tw from "twin.macro"
import styled from "@emotion/styled"

import Modal from "@src/components/ui/Modal"

export const Title = styled.div`
  ${tw`font-light leading-5 font-avenirBook text-white`}
`

export const ModalStyled = styled(Modal)`
  &.progress-modal {
    width: 400px;
    top: auto;
    right: auto;
    bottom: 15px;
    left: 15px;
    margin: 0;

    .box-dialog {
      font-size: 16px;
      border-radius: 10px;
      padding: 0;
      overflow: hidden;

      .close {
        display: none;
      }

      .box-content-wrapper {
        ${tw`p-0 overflow-hidden relative mt-0`}

        .box-content {
          ${tw`text-15/16`}
          .header {
            ${tw`py-15/16 px-7.5 w-full flex justify-between items-center`}
          }

          .body {
            ${tw`font-avenirMedium px-7.5 pt-5 pb-7.5`}
          }
        }
      }
    }
  }
`

export const DownloadProgressContent = styled.div<{
  isCollapsed: boolean
}>`
  overflow: hidden;

  ${props =>
    props.isCollapsed
      ? `
      max-height: 0;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);`
      : `
      max-height: 1000px;
      transition: max-height 1.5s ease-in-out;
    `};
`

export const LoadingDots = styled.div`
  position: relative;
  margin: 0 20px;

  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #3d4353;
    }
    30% {
      box-shadow: 10014px 0 0 2px #3d4353;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #3d4353;
    }
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #3d4353;
    }
    30% {
      box-shadow: 9984px 0 0 2px #3d4353;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #3d4353;
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #3d4353;
    }
    30% {
      box-shadow: 9999px 0 0 2px #3d4353;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #3d4353;
    }
  }

  .dot-pulse {
    position: relative;
    left: -9999px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;

    &:before,
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      width: 3px;
      height: 3px;
      border-radius: 50%;
    }

    &:before {
      box-shadow: 9984px 0 0 -5px #9880ff;
      animation: dotPulseBefore 1.5s infinite linear;
      animation-delay: 0s;
    }

    &:after {
      box-shadow: 10014px 0 0 -5px #9880ff;
      animation: dotPulseAfter 1.5s infinite linear;
      animation-delay: 0.5s;
    }
  }
`
