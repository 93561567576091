import axios from 'axios';
import config from "@src/config"

// update interface of window object to include activeRequests and failedRequests
declare global {
  interface Window {
    logVault?: {
      forceSendLogs: (message: string) => void;
    }
  }
}

const activeRequests = new Set(); // Track currently active requests
const failedRequests: any[] = []; // Track failed requests

export const addActiveRequest = (url: string) => activeRequests.add(url);

export const removeActiveRequest = (url: string) => activeRequests.delete(url);

export const logFailedRequest = (url: string, details: any) => {
  failedRequests.push({ url, ...details, timestamp: new Date() });
};

const logVault = axios.create({
  baseURL: config.LOG_VAULT_URL, // Replace with your deployed Cloud Run URL
  timeout: 5000,
});

export const logSpinnerTimeout = (message: any) => {
  logVault.post('/log-spinner-timeout', { message, activeRequests, failedRequests })
    .then(() => console.log('Spinner timeout logged successfully'))
    .catch((error) => console.error('Failed to log spinner timeout:', error));
};

if (typeof window === "object") {
  window.logVault = {
    forceSendLogs: logSpinnerTimeout,
  };
}
