import React from "react"
import { withProperties } from "@src/utils/type"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { css as cssImport } from "@emotion/react"

import DownloadIcon from "@src/images/icons/download.svg"
import Text from "@src/components/ui/Text"

interface ButtonProps {
  children?: any
  className?: any
}

const ButtonGroup = styled.div`
  & > * {
    ${tw`py-2 px-4`}

    &:focus {
      outline: 0;
    }
  }
  & > *:first-of-type {
    ${tw`rounded-l-full rounded-r-none pl-7`};
  }
  & > *:last-child {
    ${tw`rounded-r-full rounded-l-none pr-7`};
  }
`

const ButtonStyled = styled.button`
  ${tw`font-avenirRoman text-15/16 text-center focus:outline-none relative`}
  transition: all 0.2s;
  overflow: hidden;

  &:disabled {
    ${tw`opacity-30`}
  }

  &::after {
    content: "";
    border-radius: inherit;
    ${tw`absolute inset-0 pointer-events-none`}
    transition: background-color 0.2s;
  }

  &:hover::after {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:focus::after {
    background-color: rgba(0, 0, 0, 0.15);
  }

  ${({ css }: any) => css && cssImport`${css}`}
`

function Button(props: ButtonProps): any {
  const { children, className } = props
  if (React.Children.toArray(children).length > 1) {
    return (
      <ButtonGroup className={[className, "flex"].join(" ")}>
        {children}
      </ButtonGroup>
    )
  }
  return <Item {...props} />
}

const Item: React.FunctionComponent<{
  children?: any
  className?: any
}> = (props) => (
  <ButtonStyled
    {...props}
    className={`px-6.5 h-10 rounded-full ${props.className || ""}`}
  />
)

export const ButtonLightBlue: React.FunctionComponent<
  {
    children?: any
    className?: any
  } & any
> = ({ children, className, ...props }) => (
  <Item {...props} className={`bg-gray-22 text-blue-25 ${className || ""}`}>
    {children}
  </Item>
)

export const ButtonOutLineBlue: React.FunctionComponent<
  {
    children?: any
    className?: any
  } & any
> = ({ children, className, ...props }) => (
  <Item
    {...props}
    className={`border-blue-5 border text-blue-25 bg-white ${className || ""}`}
  >
    {children}
  </Item>
)

export const ButtonBlue: React.FunctionComponent<
  {
    children?: any
    className?: string
  } & any
> = ({ children, className, ...props }) => (
  <Item {...props} className={`${className || ""} bg-blue-25 text-white`}>
    {children}
  </Item>
)

export const ButtonDownload: React.FunctionComponent<
  {
    children?: any
    className?: any
  } & any
> = ({ children, className, ...props }) => (
  <button {...props} className={`${className || ""} focus:outline-none`}>
    <Text.body
      css={css`
        ${tw`font-black text-sm text-blue-25 inline-block font-avenirHeavy`}
      `}
    >
      {children || "Download .CSV"}
    </Text.body>
    <img src={DownloadIcon} alt="" className="ml-4 inline-block" />
  </button>
)

export const ButtonRed: React.FunctionComponent<
  {
    children?: any
    className?: any
  } & any
> = ({ children, className, ...props }) => (
  <Item
    {...props}
    className={`bg-red-10 text-white ${className || ""}
      `}
  >
    {children}
  </Item>
)

export const ButtonOrange: React.FunctionComponent<
  {
    children?: any
    className?: any
  } & any
> = ({ children, className, ...props }) => (
  <Item
    {...props}
    className={`
        bg-orange-2 text-white ${className || ""}
      `}
  >
    {children}
  </Item>
)

export const ButtonCustom: React.FunctionComponent<
  {
    children?: any
    className?: any
  } & any
> = ({ children, className, ...props }) => (
  <Item {...props} className={` ${className || ""}`}>
    {children}
  </Item>
)

const ButtonOutlineStyled = styled.button`
  ${tw`font-avenirRoman text-15/16 text-center border rounded-full
    focus:outline-none relative h-10 px-6.5`}
  transition: all 0.2s;

  &:disabled {
    ${tw`opacity-30`}
  }

  &::after {
    content: "";
    ${tw`absolute top-0 left-0 right-0 bottom-0 pointer-events-none rounded-full invisible`}
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:hover {
    border-width: 2px;
  }

  &:focus {
    border-width: 2px;
    &::after {
      ${tw`visible`}
    }
  }
`

export const ButtonOutline: React.FunctionComponent<
  {
    children?: any
    className?: any
  } & any
> = ({ children, className, ...props }) => (
  <ButtonOutlineStyled {...props} className={`${className || ""}`}>
    {children}
  </ButtonOutlineStyled>
)

export default withProperties(Button, { Item })
