import { useEffect, useState } from "react"

import tickGreenIcon from "@src/images/icons/tickgreen.svg"
import { ButtonDownload } from "@src/components/ui/Button"
import { openNewTab } from "@src/utils/openNewTab"

interface Props {
  downloadUri: string
  fileName: string
  fileSize?: number
}

const oneMB = 1024 * 1024

function getFileInMB(fileSize: number): string {
  if (fileSize < 1024) {
    return `${(fileSize / 1024).toFixed(3)  } kb`
  }

  if (fileSize >= 1024 && fileSize < oneMB) {
    return `${(fileSize / 1024).toFixed(2)  } kb`
  }

  return `${(fileSize / (1024 * 1024)).toFixed(2)  } mb`
}

export function DownloadReadyLayout(props: Props) {
  const { downloadUri, fileName, fileSize } = props
  const [supportAutoDownload, setSupportAutoDownload] = useState(true)
  const [isDeletedFile, setIsDeleteFile] = useState(false)

  useEffect(() => {
    autoDownload()
  }, [])

  useEffect(() => {
    const timeoutDeleteFile = setTimeout(() => {
      // After 5 minutes set show deleted file popup
      setIsDeleteFile(true)
    }, 300000)

    return () => {
      clearTimeout(timeoutDeleteFile)
    }
  }, [])

  function autoDownload(): void {
    const _window = openNewTab(downloadUri)

    // it will be null if the browser block to open new popup automatically
    if (!_window) {
      setSupportAutoDownload(false)
    }
  }

  function manualDownload(): void {
    // when user interact with browser, we can use window.open method
    autoDownload()
    setSupportAutoDownload(true)
  }

  if (isDeletedFile && !supportAutoDownload) {
    return (
      <div className="flex items-center justify-between text-black-1">
        <div>The file has been deleted after 5 minutes automatically</div>
      </div>
    )
  }

  return (
    <>
      {!supportAutoDownload ? (
        <>
          <div className="text-black-1">
            If your download doesn't start automatically, click the link
            below...
          </div>
          <div className="flex items-center justify-between text-black-1 mt-2">
            <div className="flex items-center">
              <ButtonDownload
                className="-ml-4 mr-4 flex flex-row-reverse items-center"
                onClick={manualDownload}
              >
                <div className="text-blue-25 ml-4 font-avenirRoman flex items-center">
                  <div className="underline truncate max-w-44">{fileName}</div>
                  {fileSize && (
                    <div className="text-black-1 ml-3.5 w-16">
                      ({getFileInMB(fileSize)})
                    </div>
                  )}
                </div>
              </ButtonDownload>
            </div>
            <img src={tickGreenIcon} />
          </div>
        </>
      ) : (
        <div className="flex items-center justify-between text-black-1">
          <div>Download successful!</div>
          <img src={tickGreenIcon} />
        </div>
      )}
    </>
  )
}
