import React, { useState, useRef, useEffect } from "react"
import { IoMdClose } from "react-icons/io"
import { FiChevronDown , FiChevronUp } from "react-icons/fi"

import { ModalProps } from "@src/components/ui/Modal"
import API from "src/requests"
import { toast } from "@src/components/ui/Toast"

import { ModalStyled, Title, DownloadProgressContent } from "./styles"
import { DownloadReadyLayout } from "./DownloadReadyLayout"
import { PreparingDownloadLayout } from "./PreparingDownloadLayout"

export interface DownLoadProgressModalData {
  uri: string
  progressUri: string
  body?: any
  preparingTitle: string
}

interface DownLoadProgressModalProps extends ModalProps {
  data: DownLoadProgressModalData
}

export interface ProgressResponse {
  fileName: string
  success: number
  progress: number
  error?: string
  message?: string
  fileSize?: number
  downloadUri?: string
}

const RECALL_TIMEOUT = 10000
let checkProgressTimeout: NodeJS.Timeout

const DownLoadProgressModal: React.FC<DownLoadProgressModalProps> = props => {
  const modalRef: any = useRef()
  const { data } = props
  const { uri, progressUri, preparingTitle, body } = data

  const [error, setError] = useState("")
  const [token, setToken] = useState("")
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [progress, setProgress] = useState<ProgressResponse>({
    success: 0,
    progress: 0,
    fileName: "",
  })

  const viewConfig = {
    headerClass: progress.success ? "bg-green-6" : "bg-black-1",
    headerTitle: progress.success
      ? "Download ready!"
      : "Preparing your download...",
  }

  useEffect(() => {
    const fetchData = (token: string) => {
      setToken(token)

      window.addEventListener("beforeunload", onbeforeunload)

      checkProgressTimeout = setTimeout(() => {
        checkExportingProgress(token)
      }, RECALL_TIMEOUT)
    }

    const handleError = (e: any) => {
      const message =
        e.response && e.response.data ? e.response.data : e.message
      toast.error(message)
      closeModal()
    }

    if (body) {
      API.post(uri, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(res => fetchData(res.data))
        .catch(handleError)
    } else {
      API.get(uri)
        .then(res => fetchData(res.data))
        .catch(handleError)
    }

    return () => {
      window.removeEventListener("beforeunload", onbeforeunload)
    }
  }, [])

  function onbeforeunload(e: any): any {
    const mgs =
      "Are you sure you want to leave this page? Your pending download will be lost!"
    e.returnValue = mgs

    return mgs
  }

  async function checkExportingProgress(token: string): Promise<void> {
    let data: ProgressResponse

    try {
      // check progress exists params
      const url =
        progressUri.indexOf("?") > -1 ? `&token=${token}` : `?token=${token}`

      const res = await API.post(`${progressUri}${url}`)
      data = res.data
    } catch (e: any) {
      setError(e.message)
      return
    }

    if (data.error) {
      setError(data.error)
      return
    }

    setProgress(data)

    if (!data.success) {
      checkProgressTimeout = setTimeout(() => {
        checkExportingProgress(token)
      }, RECALL_TIMEOUT)
      
    } else {
      window.removeEventListener("beforeunload", onbeforeunload)
    }
  }

  function closeModal(): void {
    modalRef.current.close()
  }

  function cancelDownload(token: string): void {
    clearTimeout(checkProgressTimeout)
    API.delete(`${progressUri}&token=${token}`)
    closeModal()
  }

  function onCloseIconClick(): void {
    if (progress.success) {
      closeModal()
    } else {
      cancelDownload(token)
    }
  }

  function collapse(): void {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <ModalStyled
      {...props}
      className="progress-modal"
      noBackdrop={true}
      ref={modalRef}
    >
      <div className={`header ${viewConfig.headerClass} `}>
        <Title>{viewConfig.headerTitle}</Title>
        <div className="flex">
          {isCollapsed ? (
            <FiChevronUp
              size={20}
              color="white"
              className="mr-4 cursor-pointer"
              onClick={collapse}
            />
          ) : (
            <FiChevronDown
              size={20}
              color="white"
              className="mr-4 cursor-pointer"
              onClick={collapse}
            />
          )}
          <IoMdClose
            size={20}
            color="white"
            onClick={onCloseIconClick}
            className="cursor-pointer"
          />
        </div>
      </div>

      <DownloadProgressContent isCollapsed={isCollapsed}>
        <div className="body">
          {progress.success ? (
            <DownloadReadyLayout
              downloadUri={progress.downloadUri ? progress.downloadUri : ""}
              fileName={progress.fileName}
              fileSize={progress.fileSize}
            />
          ) : (
            <PreparingDownloadLayout
              data={progress}
              token={token}
              error={error}
              preparingTitle={preparingTitle}
            />
          )}
        </div>
      </DownloadProgressContent>
    </ModalStyled>
  )
}

export default DownLoadProgressModal
